import Checkbox from "./checkbox";
import { useEffect, useState } from "react";
import { POST } from "../https";
import { useFormik } from "formik";
import * as Yup from "yup";
import { gsap } from "gsap";
const Form = ({
  language,
  inputElement,
  enable,
  isChcked,
  setIsChecked,
  selected,
  isCheckbox,
  isWeb,
}) => {
  const [label, setLabel] = useState(
    language === "en"
      ? "I agree to privacy & policy"
      : "Acconsento al trattamento dei miei dati personali secondo le modalità espresse nella Privacy Policy"
  );
  const [btn, setBtn] = useState("");
  const [btn_1, setBtn_1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  // useEffect(() => {
  //   const button = document.querySelector(".pkg__send-form-button-1");
  //   function mouseover() {
  //     button.classList.add("hovered");
  //     button.innerHTML = btn;
  //   }
  //   function mouseout() {
  //     button.classList.remove("hovered");
  //     button.innerHTML = btn_1;
  //   }
  //   button.addEventListener("mouseover", mouseover);
  //   button.addEventListener("mouseout", mouseout);

  //   return () => {
  //     button.removeEventListener("mouseover", mouseover);
  //     button.removeEventListener("mouseover", mouseout);
  //   };
  // }, [btn, btn_1]);

  // useEffect(() => {
  //   language === "it" ? setBtn("SCOPRI DI PIU") : setBtn("FIND OUT MORE");
  //   language === "it" ? setBtn_1("INVIA MESSAGGIO") : setBtn_1("SEND MESSAGE");
  // });
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(
        language === "en" ? "Email is required" : "L'e-mail è obbligatoria"
      )
      .required(),

    phone_number: Yup.string()
      .matches(/^\+?\d{10,12}$/)
      .required(
        language === "en"
          ? "Phone number is required"
          : "Il numero di telefono è obbligatorio"
      ),
  });
  const formik = useFormik(
    {
      initialValues: {
        package_name: "",
        name: "",
        surname: "",
        email: "",
        phone_number: "",
        message: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const user_request = {
          data: {
            package_name: selected,
            name: values.name,
            surname: values.surname,
            email: values.email,
            phone_number: values.phone_number,
            message: values.message,
          },
        };
        if (isChcked) {
          try {
            POST(user_request, "/user-requests");
            sendEmail();
            setIsSubmitted(true);
            setTimeout(() => {
              setIsSubmitted(false);
            }, 5000);
            formik.resetForm();
          } catch (err) {
            throw new Error(err);
          }
        }
      },
    },
    []
  );

  function sendEmail() {
    window.Email.send({
      SecureToken: process.env.REACT_APP_EMAIL_TOKEN,
      To: [process.env.REACT_APP_EMAIL_RE1, process.env.REACT_APP_EMAIL_RE2],
      From: process.env.REACT_APP_EMAIL_SENDER,
      Subject: `You have a new from ` + formik.values.name + ``,
      Body:
        `<div>
        <h1>ALTAIR</h1>
        </br>
        </br>
        <p>You have a request for following information :-</p>
        <p>Package :<strong> ` +
        selected.toUpperCase() +
        `</strong>
        </p> 
         <p>Name : <strong> ` +
        formik.values.name +
        ` </strong></p>
        <p>Surname : <strong>` +
        formik.values.surname +
        `</strong></p>
         <p>Email : <strong> ` +
        formik.values.email +
        `</strong></p>
         <p>Phone number : <strong>` +
        formik.values.phone_number +
        `</strong></p>
         <p><strong>Message</strong> : ` +
        formik.values.message +
        `</p>
        </div>`,
    });
  }
  function onFocus() {
    if (window.innerWidth < 800) {
      gsap.to(".menu__track", { width: 567 });
      gsap.to(".menu", { x: -505 });
    }
  }
  return (
    <form
      onSubmit={formik.handleSubmit}
      action=""
      className={!isWeb ? "pkg__send-form" : "pkg__send-form margin--left"}>
      <div className="pkg__send-form--first-row">
        <div className="pkg__send-form--c">
          <label for="name">{language === "en" ? "Name*" : "Name*"}</label>
          <input
            ref={inputElement}
            onChange={formik.handleChange}
            id="name"
            type="text"
            value={formik.values.name}
            name="name"
            placeholder="Mario"
            disabled={!enable}
            required={true}
            onFocus={onFocus}
            onBlur={onFocus}
            autoComplete="off"
          />
        </div>
        <div className="pkg__send-form--c">
          <label for="surname">
            {language === "en" ? "Surname*" : "Cognome*"}
          </label>
          <input
            ref={inputElement}
            onChange={formik.handleChange}
            id="surname"
            type="text"
            name="surname"
            value={formik.values.surname}
            placeholder="Rosi"
            disabled={!enable}
            required={true}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onFocus}
          />
        </div>
      </div>
      <div className="pkg__send-form--second-row">
        <div className="pkg__send-form--c">
          <label for="tel">
            {language === "en" ? "Phone number*" : "Numero di telefono*"}
          </label>
          <input
            onChange={formik.handleChange}
            ref={inputElement}
            id="tel"
            type="tel"
            value={formik.values.phone_number}
            name="phone_number"
            placeholder="123 456789"
            disabled={!enable}
            required={true}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onFocus}
          />
        </div>
        <div className="pkg__send-form--c">
          <label for="email">{language === "en" ? "Email*" : "Email*"}</label>
          <input
            ref={inputElement}
            onChange={formik.handleChange}
            id="email"
            type="email"
            name="email"
            value={formik.values.email}
            placeholder="mariorossi@email.com"
            disabled={!enable}
            required={true}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onFocus}
          />
        </div>
      </div>
      <div className="pkg__send-form--third-row">
        <div className="pkg__send-form--c">
          <label for="message">
            {language === "en" ? "Message*" : "Messaggio*"}
          </label>
          <textarea
            className="message"
            ref={inputElement}
            onChange={formik.handleChange}
            id="message"
            name="message"
            value={formik.values.message}
            type="text"
            placeholder="Facci sapere cosa hai in mente e quando possiamo ricontattarti."
            disabled={!enable}
            required={true}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onFocus}
          />
        </div>
      </div>
      {isCheckbox ? (
        <Checkbox
          disabled={!enable}
          checked={isChcked}
          setchecked={setIsChecked}
          label={label}
        />
      ) : undefined}

      <button
        disabled={!enable}
        type="submit"
        className={"pkg__send-form-button-1"}>
        {language === "it" ? "INVIA MESSAGGIO" : "SEND MESSAGE"}
      </button>

      {isSubmitted && (
        <span
          style={{
            margin: "0 0 0 25px",
            fontSize: "20px",
            color: "#fff",
            border: "2px solid #fff",
            width: "100%",
            textAlign: "center",
            padding: "5px 0",
          }}>
          {language === "it"
            ? "Messaggio Inviato con successo!"
            : "Message sent successfully!"}
        </span>
      )}
    </form>
  );
};

export default Form;

import { useRef } from "react";
import { useNavigate } from "react-router";
import web from "../svgs/Siti Web & E-Commerce.svg";
import AnimatedPage from "./page2";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ScrollbarContext } from "../store";
import { gsap } from "gsap";
import arrow_left from "../images/arrow-left.svg";
import gas_blue from "../svgs/GRADIENT SFONDO 4.svg";
import galaxy from "../svgs/GALASSIA SFONDO.png";
import {
  onReloadPage,
  pageHandler,
  ScrollToContact,
  urlHander,
} from "../page_utile";
import { GET } from "../https";
import Meta from "../components/meta";
import ReactMarkdown from "react-markdown";
import { cachePos } from "../storage";
import { Link } from "react-router-dom";
import ContactPage from "./contatti";
import { toHome } from "../page_utile/pageTransition";

const EComerece = () => {
  const phone = window.matchMedia("max-width:500px");
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [fetchData, setfetchData] = useState({});
  const nav = useNavigate();
  function restore() {
    gsap.to(".benefici", { display: "flex" });
    gsap.to(".rece", { display: "flex" });
    gsap.to(".contact", { display: "flex" });
  }

  const { first_text, second_text, SEO, title } = fetchData;
  const refBrand = useRef(null);
  const { scroll, position, language, positionSetter } =
    useContext(ScrollbarContext);

  useEffect(() => {
    positionSetter(0);
    const ScrollEvent = () => {
      if (
        window.location.href ===
        window.location.origin + urlHander(SEO?.seoTitle)
      ) {
        if (scroll.scrollTop < position) {
          scroll.scrollTo(0, position);
        }
        if (
          scroll.scrollTop >
          position + refBrand.current?.clientHeight * 0.5
        ) {
          scroll.scrollTo(0, position + refBrand.current?.clientHeight * 0.5);
        }
      }
    };
    scroll?.addListener(ScrollEvent);
    return () => {
      scroll?.removeListener(ScrollEvent);
    };
  });
  useEffect(() => {
    const setCache = async () => {
      if (position !== 0) {
        await cachePos.setItem("pos", position);
      }
    };
    setCache();
  }, []);

  useEffect(() => {
    cachePos.getItem("pos").then((value) => {
      onReloadPage(urlHander(SEO?.seoTitle), 0, scroll, positionSetter);
    });
  });
  useEffect(() => {
    setLoader(false);
    setShow(false);
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`);
        setfetchData(result.data[8].attributes);
        setTimeout(() => {
          setShow(true);
          setLoader(true);
        }, 10);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);

  useEffect(() => {
    pageHandler(urlHander(SEO?.seoTitle), setShow);
    window.addEventListener("load", pageHandler);
    return () => {
      window.removeEventListener("load", pageHandler);
    };
  }, []);
  useEffect(() => {
    if (loader) {
      gsap.fromTo(
        refBrand.current,
        { y: 0 },
        {
          y: -refBrand.current?.clientHeight * 0.8,
          ease: "none",
          scrollTrigger: {
            target: "#smooth",
            start: position ? position : 0,
            end: "+=800",
            scrub: true,
          },
        }
      );
    }
  });

  const pageTranstion = () => {
    gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" });
    toHome();
    scroll.scrollTo(0, 0);
    nav("/");
  };
  function ContactTranstion() {
    ScrollToContact(scroll, position, nav);
  }
  return (
    <>
      {show && (
        <AnimatedPage>
          <main className="service-detail web">
            <div ref={refBrand} className="service-detail__brand margin">
              <Meta
                title={SEO?.seoTitle}
                description={SEO?.seoDescription}
                keywords={SEO?.seoKeywords}
              />
              <img className="g" src={gas_blue} alt="gas-effect" />
              <img src={galaxy} alt="galaxy" className="x" />
              <h1 style={{ fontSize: "41px", color: "#fff" }}>{title}</h1>

              <ReactMarkdown>{first_text}</ReactMarkdown>

              <img src={web} alt="social" />

              <ReactMarkdown className="margin--bottom">
                {second_text}
              </ReactMarkdown>

              {loader && <ContactPage />}
              <div className="service-detail__brand_footer">
                <div className="service-detail__brand_footer-together">
                  <p className="service-detail__brand_footer-num">
                    P.IVA 16413921004
                  </p>
                  <div className="service-detail__brand_footer-wrapper">
                    <Link>PRIVACY</Link>
                    <Link>COOKIES</Link>
                  </div>
                </div>
                <p className="service-detail__brand_footer-copyright">
                  Copyright | Altair Agency 2023
                </p>
              </div>
            </div>
            <img
              onClick={pageTranstion}
              src={arrow_left}
              alt="arrow-left"
              id="toggle"
              className="service-detail__left-arrow"
            />
          </main>
        </AnimatedPage>
      )}
    </>
  );
};

export default EComerece;

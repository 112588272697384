import altairLogo from "../svgs/LOGO.svg";
import logoShadow from "../svgs/GRADIENT NERO SFONDO LOGO.svg";
import astroy from "../svgs/STELLA BUCO NERO.png";
import white_elipse from "../svgs/Ellipse 1.svg";
import { useContext, useEffect, useState } from "react";
import { ScrollbarContext } from "../store";
import { NavLink } from "react-router-dom";
import darkNav from "../svgs/Rectangle 363.svg";
import whiteMenu from "../svgs/menu_white.svg";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import {
  chiaBackward,
  problemiBackward,
  toEcomerce,
  toHome,
} from "../page_utile/pageTransition";
import { GET } from "../https";
import cross from "../svgs/cross-svgrepo-com.svg";
import { Detector } from "react-detect-offline";
const Header = () => {
  const nav = useNavigate();
  const { languageChange, position, scroll, language, navPageSecretM } =
    useContext(ScrollbarContext);

  const [togClass, setTogClass] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [fetchData, setfetchData] = useState({});
  const [fetchData2, setfetchData2] = useState({});

  useEffect(() => {
    const brugerMenu = () => {
      if (window.innerWidth < 1200) {
        setIsMenu(true);
      } else {
        setIsMenu(false);
      }
    };
    brugerMenu();
    window.addEventListener("resize", brugerMenu);
    return () => {
      window.removeEventListener("resize", brugerMenu);
    };
  }, [setIsMenu]);

  useEffect(() => {
    const ScrollEvent = () => {
      if (isMenuOpen) {
        scroll.scrollTo(0, position);
      }
    };
    scroll?.addListener(ScrollEvent);
    return () => {
      scroll?.removeListener(ScrollEvent);
    };
  });

  const menuActive = () => {
    gsap.to(".header__menu", { xPercent: -100, duration: 1 });
    gsap.set(".menu", { zIndex: 100 });
    setIsMenuOpen(true);
    // positionSetter(scroll?.scrollTop);
  };

  const menuNotActive = () => {
    gsap.to(".header__menu", { xPercent: 100, duration: 1 });
    gsap.set(".menu", { zIndex: 10000000000 });
    navPageSecretM(true);

    setIsMenuOpen(false);
  };
  useEffect(() => {
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`);
        setfetchData(result.data);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    const fetchHome2 = async () => {
      try {
        const result = await GET(`/pages?locale=${language}&populate=*`);

        setfetchData2(result.data);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
    fetchHome2();
  }, [language]);

  function urlHander(id, http) {
    if (fetchData) {
      const seoTitle = http
        ? http[id]?.attributes.SEO.seoTitle
        : fetchData[id]?.attributes.SEO.seoTitle;

      return "/" + seoTitle;
    }
  }

  return (
    <header className="header">
      <div className={"header__offline"}></div>
      {isMenu && (
        <div className="header__menu">
          <nav className="header__nav_menu">
            <img
              onClick={menuNotActive}
              style={{
                width: "21px",
                display: "block",
                margin: "30px 40px 0 auto",
              }}
              src={cross}
              alt="cross"
            />
            <ul className="nav__item">
              <li
                onClick={() => scroll.scrollTo(0, 0)}
                className="nav__item--1">
                <NavLink
                  to={"/"}
                  end
                  className={({ isActive }) =>
                    isActive ? "isActive" : undefined
                  }
                  onClick={() => {
                    toHome();
                    menuNotActive();
                  }}>
                  Home
                </NavLink>
              </li>
              <li className="nav__item--1">
                <NavLink
                  to={urlHander(8)}
                  className={({ isActive }) =>
                    isActive ? "isActive" : undefined
                  }
                  onClick={() => {
                    toEcomerce(scroll);
                    menuNotActive();
                  }}>
                  Siti web e E-commerce
                </NavLink>
              </li>
              <li className="nav__item--1">
                <NavLink>Blog</NavLink>
              </li>
              <li className="nav__item--1">
                <NavLink
                  onClick={() => {
                    toEcomerce(scroll);
                    menuNotActive();
                  }}
                  className={({ isActive }) =>
                    isActive ? "isActive" : undefined
                  }
                  to={urlHander(3, fetchData2)}>
                  Unisciti a noi
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      )}
      <img
        src={white_elipse}
        alt="white-elipse"
        className="header__white-elipse"
      />
      <img
        src={white_elipse}
        alt="white-elipse"
        className="header__white-elipse"
      />
      <img
        src={white_elipse}
        alt="white-elipse"
        className="header__white-elipse--left"
      />
      <img
        src={white_elipse}
        alt="white-elipse"
        className="header__white-elipse--left"
      />
      {!isMenu && (
        <nav className="nav">
          <ul className="nav__item">
            <li onClick={() => scroll.scrollTo(0, 0)} className="nav__item--1">
              <NavLink
                to={"/"}
                end
                className={({ isActive }) =>
                  isActive ? "isActive" : undefined
                }
                onClick={() => {
                  toHome();
                  navPageSecretM(true);
                }}>
                Home
              </NavLink>
            </li>
            <li className="nav__item--1">
              <NavLink
                to={urlHander(8)}
                className={({ isActive }) =>
                  isActive ? "isActive" : undefined
                }
                onClick={() => {
                  toEcomerce(scroll);
                  navPageSecretM(true);
                }}>
                Siti web e E-commerce
              </NavLink>
            </li>
            <li className="nav__item--1">
              <NavLink>Blog</NavLink>
            </li>
            <li className="nav__item--1">
              <NavLink
                onClick={() => {
                  toEcomerce(scroll);
                  navPageSecretM(true);
                }}
                className={({ isActive }) =>
                  isActive ? "isActive" : undefined
                }
                to={urlHander(3, fetchData2)}>
                Unisciti a noi
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
      <img src={astroy} alt="astroy" className="header__astroy" />
      <img className="header__logo-shadow" src={logoShadow} alt="logo-shadow" />
      <div className="header__logo">
        <img src={altairLogo} alt="altair-logo" />
      </div>
      {isMenu && (
        <div className="header__lan">
          <img onClick={menuActive} src={whiteMenu} alt="bruger menu" />
        </div>
      )}
      {/* <div className="header__lan">
        <button
          className={togClass ? "header__lan--en" : "relative"}
          style={{ zIndex: 9000000000000000 }}
          onClick={() => {
            languageChange("it");
            setTogClass(false);
          }}>
          IT
        </button>
        <button
          className={!togClass ? "header__lan--it" : "relative"}
          style={{ zIndex: 900000000000000 }}
          onClick={() => {
            languageChange("en");
            setTogClass(true);
          }}>
          EN
        </button>
      </div> */}
    </header>
  );
};

export default Header;

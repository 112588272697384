import { useRef } from "react";
import { useNavigate } from "react-router";
import content from "../svgs/Content Creation.svg";
import AnimatedPage from "./page2";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ScrollbarContext } from "../store";
import { gsap } from "gsap";
import arrow_left from "../images/arrow-left.svg";
import { GET } from "../https";
import {
  onReloadPage,
  pageHandler,
  ScrollToContact,
  urlHander,
} from "../page_utile";
import Meta from "../components/meta";
import ReactMarkdown from "react-markdown";
import { cachePos } from "../storage";
import { Link } from "react-router-dom";
import gas_blue from "../svgs/GRADIENT SFONDO 4.svg";
import galaxy from "../svgs/GALASSIA SFONDO.png";

const ContentPage = () => {
  const phone = window.matchMedia("(max-width:500px)");
  const [fetchData, setfetchData] = useState({});
  const { language } = useContext(ScrollbarContext);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const nav = useNavigate();
  function restore() {
    gsap.to(".benefici", { display: "flex" });
    gsap.to(".rece", { display: "flex" });
    gsap.to(".contact", { display: "flex" });
  }
  const { first_text, img_brand, second_text, SEO, title } = fetchData;
  const refBrand = useRef(null);
  const { scroll, position, positionSetter } = useContext(ScrollbarContext);

  useEffect(() => {
    const ScrollEvent = () => {
      if (
        window.location.href ===
        window.location.origin + urlHander(SEO?.seoTitle)
      ) {
        if (scroll.scrollTop < position) {
          scroll.scrollTo(0, position);
        }
        if (
          scroll.scrollTop >
          position + refBrand.current?.clientHeight * 0.5
        ) {
          scroll.scrollTo(0, position + refBrand.current?.clientHeight * 0.5);
        }
      }
    };
    scroll?.addListener(ScrollEvent);
    return () => {
      scroll?.removeListener(ScrollEvent);
    };
  });

  function ContactTranstion() {
    ScrollToContact(scroll, position, nav);
    restore();
  }

  useEffect(() => {
    setLoader(false);
    setShow(false);
    const fetchHome = async () => {
      try {
        const result = await GET(`/services?locale=${language}&populate=*`);
        setfetchData(result.data[3].attributes);
        setTimeout(() => {
          setShow(true);
          setLoader(true);
        }, 10);
      } catch (err) {
        console.log("server failed to fetch", err);
      }
    };
    fetchHome();
  }, [language]);

  useEffect(() => {
    const setCache = async () => {
      if (position !== 0) {
        await cachePos.setItem("pos", position);
      }
    };
    setCache();
  }, []);

  useEffect(() => {
    cachePos.getItem("pos").then((value) => {
      onReloadPage(
        urlHander(SEO?.seoTitle),
        phone.matches ? 3966 : 3940,
        scroll,
        positionSetter
      );
    });
  });

  useEffect(() => {
    if (loader) {
      gsap.fromTo(
        refBrand.current,
        { y: 0 },
        {
          y: -refBrand.current?.clientHeight * 0.8,

          ease: "none",
          scrollTrigger: {
            target: "#smooth",
            start: position ? position : phone.matches ? 3966 : 4049,
            end: "+=800",
            scrub: true,
          },
        }
      );
    }
  });

  useEffect(() => {
    pageHandler(urlHander(SEO?.seoTitle), setShow);
    window.addEventListener("load", pageHandler);
    return () => {
      window.removeEventListener("load", pageHandler);
    };
  }, []);

  const pageTranstion = () => {
    gsap.to(".service-detail", { opacity: 0, duration: 2 });
    setTimeout(() => nav("/"), 2000);
    setTimeout(() => gsap.to(".servizi", { xPercent: 0, duration: 2 }), 300);

    gsap.to(".menu", { yPercent: 0, ease: "Expo.easeInOut" });
    scroll.scrollTo(0, position);
    restore();
  };
  return (
    <>
      {show && (
        <AnimatedPage>
          <div ref={refBrand} className="service-detail__brand">
            <Meta
              title={SEO?.seoTitle}
              description={SEO?.seoDescription}
              keywords={SEO?.seoKeywords}
            />
            <img className="g" src={gas_blue} alt="gas-effect" />
            <img src={galaxy} alt="galaxy" className="x" />
            <h1 style={{ fontSize: "41px", color: "#fff" }}>{title}</h1>

            <ReactMarkdown>{first_text}</ReactMarkdown>

            <img src={content} alt="content" />

            <ReactMarkdown>{second_text}</ReactMarkdown>

            <button
              className="service-detail__brand--btn"
              onClick={ContactTranstion}>
              {language === "en" ? "contacts" : "contatti"}
            </button>
            <div className="service-detail__brand_footer">
              <div className="service-detail__brand_footer-together">
                <p className="service-detail__brand_footer-num">
                  P.IVA 16413921004
                </p>
                <div className="service-detail__brand_footer-wrapper">
                  <Link>PRIVACY</Link>
                  <Link>COOKIES</Link>
                </div>
              </div>
              <p className="service-detail__brand_footer-copyright">
                Copyright | Altair Agency 2023
              </p>
            </div>
          </div>
          <img
            onClick={pageTranstion}
            src={arrow_left}
            alt="arrow-left"
            id="toggle"
            className="service-detail__left-arrow"
          />
        </AnimatedPage>
      )}
    </>
  );
};

export default ContentPage;
